import React from 'react';

const LogoVertical = (props) => {
  return (
    <img
      alt="Logo"
      src="/static/logo_vertical.png"
      {...props}
    />
  );
};

export default LogoVertical;
