import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import decryptString from 'src/utils/decryptString';
import NavItem from './NavItem';
import PropTypes from 'prop-types';

import {
  Avatar,
  Box,
  Badge,
  Button,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  Typography,
  makeStyles,
  withStyles
} from '@material-ui/core';

import {
  LogOut as LogOutIcon,
  LogIn as LogInIcon,
  Loader as LoaderIcon,
  Save as SaveIcon,
  Calendar as CalendarIcon,
  Package as PackageIcon,
  Compass as CompassIcon
} from 'react-feather';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const items = [
  {
    href: '/app/report',
    icon: SaveIcon,
    title: 'View Reports',
    user: 'all'
  },
  {
    href: '/app/status',
    icon: CompassIcon,
    title: 'Sample History',
    user: 'all'
  },
  {
    href: '/app/test-history',
    icon: LoaderIcon,
    title: 'Booking History',
    user: 'all'
  },
  {
    href: '/book-test',
    icon: CalendarIcon,
    title: 'Book Appointment',
    user: 'all'
  },
  {
    href: '/packages',
    icon: PackageIcon,
    title: 'Health Packages',
    user: 'all'
  },
];

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: '-1px',
      left: '-1px',
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  },
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  tyobeLogo: {
    width: '100px',
    margin: '10px auto 0px',
    display: 'block'
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const [logStatus, setlogOutStatus] = React.useState(false);
  const [customerData, setcustomerData] = React.useState('');

  localStorage.setItem('prevPath', window.location.pathname)

  const navigate = useNavigate();
  const [open] = React.useState(true);

  const handleLogout = () => {
    localStorage.removeItem('eToken')
    navigate('/login', { replace: true })
  };
  const handleClose = () => {
    setlogOutStatus(false);
  };


  const location = useLocation();
  const authToken = localStorage.getItem('eToken');

  const logOutClick = () => {
    setlogOutStatus(true)
  }
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    if (authToken)
      setcustomerData(JSON.parse(decryptString(authToken)))
  }, [location.pathname, authToken]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          <Box>
            <Typography
              className={classes.name}
              color="textPrimary"
              variant="h6"
            >
              Current User
            </Typography>
            <Typography
              className={classes.name}
              color="textPrimary"
              variant="h5"
            >
              {customerData?.name ? customerData.name : customerData?.mobile ? customerData.mobile : "Guest"}
            </Typography>
          </Box>
          <Box>
            <IconButton color="primary" style={{ padding: '0' }}>
              <StyledBadge
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                variant="dot"
              >
                <Avatar>
                  <AccountCircleIcon />
                </Avatar>
              </StyledBadge>
            </IconButton>
          </Box>
        </Box>
        <Divider />

        <Box p={2}>
          <List>
            {items.map((item) => (
              customerData?.sc_access_type === 'su' ? <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
              /> : (item.user === 'all') ? <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
              /> : null
            ))}
            <Box p={2} textAlign="center">
              {authToken ? <Button
                color="primary"
                size="small"
                type="submit"
                margin="auto"
                variant="contained"
                onClick={logOutClick}
                mt={2}>
                <LogOutIcon /> {' '} Logout
              </Button> : <Button
                color="primary"
                size="small"
                type="submit"
                margin="auto"
                variant="contained"
                onClick={() => navigate('/login', { replace: true })}
                mt={2}>
                <LogInIcon /> {' '} Login
              </Button>}
            </Box>
          </List>
        </Box>
      </Box >

      <Box p={3} textAlign="center">
        <Typography
          color="textSecondary"
          variant="h6"
          style={{ fontSize: '10px', textTransform: "uppercase" }}
        >Powered by</Typography>
        <img src="/static/tyobe-logo.png" alt='' className={classes.tyobeLogo} />
        <Typography
          color="textSecondary"
          variant="body2"
          style={{ fontSize: '10px' }}
        >Tyobe Technologies</Typography>
      </Box>
    </Box>
  );

  return (
    <>
      {logStatus ? <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Logout"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to logout now?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button onClick={handleLogout} color="primary">
            Logout
          </Button>
        </DialogActions>
      </Dialog> : null}
      <Hidden lgUp>
        <Drawer
          anchor="right"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default NavBar;
