import React, { useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import NavBar from './NavBar';
import NotificationBar from './NotificationBar';
import TopBar from './TopBar';
import {
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout = () => {
  const classes = useStyles();

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [isNotiOpen, setNotiOpen] = useState(false);
  const authToken = localStorage.getItem('eToken');


  return (
    (!authToken && window.location.pathname !== '/') ? <Navigate to="/login" /> :
      <div className={classes.root}>
        <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} onNotiPanelOpen={() => setNotiOpen(true)} />
        <NavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        />
        <NotificationBar
          onMobileClose={() => setNotiOpen(false)}
          openMobile={isNotiOpen}
        />
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
  );
};

export default DashboardLayout;
