import React from 'react';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import {
  TableCell,
  makeStyles
} from '@material-ui/core';
import StyledTableRow from 'src/components/StyledTableRow';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    }
  }, tableCell: {
    padding: '8px 3px',
  },
  tableArrow: {
    padding: '0 0 0 5px'
  }
}));

const Row = ({ className, ...rest }) => {
  const { row } = rest;
  const classes = useStyles()

  return (
    <React.Fragment>
      <StyledTableRow className={classes.root} key={uuid()}>
        <TableCell align="left">{`GK-` + row.rb_id}</TableCell>
        <TableCell align="left">{moment(row.rb_date + ' ' + row.rb_time).format("DD-MM-YYYY hh:mm:A")}</TableCell>
        <TableCell>
          {row.rb_status == 0 ? 'Pending' : null}
          {row.rb_status == 1 ? 'Confirmed' : null}
          {row.rb_status == 2 ? 'Completed' : null}
          {row.rb_status == 3 ? 'Duplicate' : null}
        </TableCell>
      </StyledTableRow>
    </React.Fragment>
  );
};

Row.propTypes = {
  className: PropTypes.string
};

export default Row;
