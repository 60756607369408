import React from 'react';
import { Document, Page as PDFPage, pdfjs } from 'react-pdf';

import {
  Container,
  makeStyles,
} from '@material-ui/core';

import Page from 'src/components/Page';
import {
  useLocation
} from "react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },

  pdfComponent: { width: '100%' },
}));

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PDFViewer = () => {
  let query = useQuery();
  const classes = useStyles();

  React.useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + 'post_reports_view.php?report_id=' + query.get("rid"), {
      method: 'POST',
      body: localStorage.getItem('eToken')
    })
      .then(response => response.json())
      .then(data => {

      }).catch(function (error) {
        console.log(error);
      });
  }, []);

  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }


  return (
    <Page
      className={classes.root}
      title="Report"
    >
      <Container maxWidth="lg">
        <Document file={query.get("url")} onLoadSuccess={onDocumentLoadSuccess} className={classes.pdfComponent} renderMode="svg">
          <PDFPage pageNumber={pageNumber} renderMode="svg"/>
        </Document>
        <p>
          Page {pageNumber} of {numPages}
        </p>
      </Container>
    </Page >
  );
};

export default PDFViewer;
