import React from "react";
import { v4 as uuid } from 'uuid';

import {
  makeStyles,
  Typography,
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import {
  ChevronRight as ChevronRightIcon
} from 'react-feather';

import decryptString from 'src/utils/decryptString';
import numberFormat from "src/utils/numberFormat";


const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'left',
    height: '100%',
    display: 'flex',
    flexFlow: 'column'
  },
  cardHead: {
    fontSize: '14px',
    fontWeight: 600
  },
  listItem: {
    padding: '0px 3px',
    alignItems: 'flex-start',
  },
  cardBody: {
    '& span': {
      lineHeight: 1.1,
      fontSize: '12px'
    }
  },
  priceBox: {
    background: theme.palette.secondary.main,
    fontSize: '14px',
    textAlign: 'center',
  },
  priceBody: {
    color: theme.palette.grey[200],
    fontSize: '12px',
    lineHeight: 2.5
  }
}));

function PackagesSection({ ...props }) {
  const [pageData, setPageData] = React.useState([]);
  const classes = useStyles();

  React.useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + 'get_package.php' + (props.category ? ("?cat=" + props.category) : ''), {
      method: 'POST',
      body: localStorage.getItem('eToken')
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === "success") {
          setPageData(JSON.parse(decryptString(JSON.stringify(data?.package_data))))
        }
      }).catch(function (error) {
        console.log(error);
      });
  }, []);


  if (!pageData) {
    return (<></>)
  }

  return (
    <>
      <Grid container spacing={3} style={{ paddingTop: "15px" }}>
        {pageData?.map((item, index) => {
          return (
            <Grid item xs={6} md={props.category ? 6 : 3} key={uuid()}>
              <Card className={classes.root} >
                {item.rpa_thumb ? <CardMedia
                  component="img"
                  image={item.rpa_thumb}
                  title={item.rpa_pack_name}
                  style={{ display: 'block' }}
                /> : null}
                <CardContent style={{ flex: 3 }}>
                  <Typography gutterBottom variant="h3" className={classes.cardHead}>
                    {item.rpa_pack_name}
                  </Typography>
                  <List component="ul" style={{ padding: 0 }}>
                    {JSON.parse(item.rpa_test_list).map((list, i) => {
                      return (
                        <ListItem key={uuid()} className={classes.listItem}>
                          <ListItemIcon style={{ minWidth: 'auto' }}>
                            <ChevronRightIcon style={{ width: 12 }} />
                          </ListItemIcon>
                          <ListItemText primary={list} className={classes.cardBody} />
                        </ListItem>
                      )
                    })}
                  </List>
                </CardContent>
                <Box className={classes.priceBox}>
                  <Typography component="p" className={classes.priceBody}>
                    <span style={{ fontSize: '18px' }}>{numberFormat(item.rpa_offer_amount)}</span> <span style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid' }}>
                      {numberFormat(item.rpa_amount)}
                    </span>
                  </Typography>
                </Box>
              </Card>
            </Grid>
          )
        })}
      </Grid>
    </>
  );
}

export default PackagesSection;
