import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';

import Home from "src/views/Home.js";
import BookTest from "src/views/account/BookTest.js";
import PrivacyPolicy from "src/views/PrivacyPolicy.js"

import DashboardView from 'src/views/reports/DashboardView';
import StatusView from 'src/views/reports/StatusView';
import TestInfo from 'src/views/reports/TestInfo';
import PDFViewer from 'src/views/reports/PDFViewer';
import PackageInfo from 'src/views/reports/PackageInfo';
import TestHistory from 'src/views/reports/TestHistory';

import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import SettingsView from 'src/views/settings/SettingsView';


const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: '/', element: <Navigate to="/" /> },
      { path: 'report', element: <DashboardView /> },
      { path: 'report/view', element: <PDFViewer /> },
      { path: 'status/:id', element: <TestInfo /> },
      { path: 'status', element: <StatusView /> },
      { path: 'test-history', element: <TestHistory /> },
      { path: 'settings', element: <SettingsView /> },
      { path: '*', element: <Navigate to="/app/" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '/', element: <Home />},
      { path: 'book-test', element: <BookTest /> },
      { path: 'packages', element: <PackageInfo /> },
    ]
  },
  { path: 'login', element: <LoginView /> },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'privacy-policy', element: <PrivacyPolicy /> },
      { path: '404', element: <NotFoundView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];
export default routes;
