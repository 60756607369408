import {
  TableCell,
  withStyles
} from '@material-ui/core';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    padding: '10px 15px'
  },
  body: {
    fontSize: 14,
    padding: '10px 15px'
  },
}))(TableCell);

export default StyledTableCell;
