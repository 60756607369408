import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';

import {
  Container,
  makeStyles,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';

import StyledTableCell from 'src/components/StyledTableCell';
import ButtonControllers from "src/views/sections/ButtonControllers.js";


import decryptString from 'src/utils/decryptString';
import Loader from 'src/services/Loader';
import Row from 'src/views/reports/TestHistory/Row';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '90%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));



const Dashboard = ({ className, ...rest }) => {
  const [pageData, setPageData] = React.useState('');

  const [open, setOpen] = React.useState(false);

  const [syncStatus, setsyncStatus] = React.useState('');
  const [alertStatus, setalertStatus] = React.useState('');
  const [alertMessage, setalertMessage] = React.useState('');


  const [dataCount, setdataCount] = React.useState(20);
  const [dataCountSetted, setdataCountStatus] = React.useState(false);

  if (!dataCountSetted && pageData.length) {
    setdataCount(pageData.length)
    setdataCountStatus(true)
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  React.useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + 'get_history.php', {
      method: 'POST',
      body: localStorage.getItem('eToken')
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === "success") {
          setPageData(JSON.parse(decryptString(JSON.stringify(data?.report_data))))
        } else {
          setsyncStatus(data.status)
        }

        if (data.status === "failed") {
          setalertStatus('error')
          setalertMessage(data.message)
          setOpen(true)
        }
      }).catch(function (error) {
        console.log(error);
      });
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const classes = useStyles();


  return (
    (!pageData && !syncStatus) ? <Loader datafromparent={{ errorMSG: alertMessage }} /> :
      <Page
        className={classes.root}
        title="Test History | GK Lab"
      >
        <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={alertStatus}>
            {alertMessage}
          </Alert>
        </Snackbar>

        {pageData.length > 0 ?
          <Container maxWidth="md" className={clsx(classes.root, className)}
            {...rest}>

            <Typography variant="h4" color="primary" style={{ marginBottom: 15 }}>
              Booking History
            </Typography>


            <Paper className={classes.paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">
                      Booking ID
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Date
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Status
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pageData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <Row row={row} key={uuid()} />
                  ))}
                </TableBody>
              </Table>
            </Paper>
            {pageData.length > 9 ?
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={dataCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              /> : null}
          </Container >
          : <Container maxWidth="md" className={clsx(classes.root, className)} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <img
              alt="Logo"
              src="/static/no-data.svg"
              width="260"
            />
            <Typography>
              Appointment History Not Found
            </Typography>
          </Container>
        }
        <Container maxWidth="md">
          <ButtonControllers hideButton={'appointment-history'} />
        </Container>
      </Page >
  );
};

Dashboard.propTypes = {
  className: PropTypes.string
};

export default Dashboard;
