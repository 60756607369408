import React, { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  makeStyles,
  MenuItem,
  Typography
} from '@material-ui/core';
import moment from 'moment';
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import Page from 'src/components/Page';
import encryptString from 'src/utils/encryptString';
import decryptString from 'src/utils/decryptString';
import Cookies from 'js-cookie';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import ButtonControllers from "src/views/sections/ButtonControllers.js";


const authToken = localStorage.getItem('eToken');

var mobileNumber = '';
if (authToken) {
  mobileNumber = JSON.parse(decryptString(authToken)).mobile
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
    minHeight: '90%'
  }
}));

const areaList = [{
  value: 'Koothanallur',
  label: 'Koothanallur',
},
{
  value: 'Laksmangudi',
  label: 'Laksmangudi',
},
{
  value: 'Podakkudi',
  label: 'Podakkudi',
},
{
  value: 'Boothamangalam',
  label: 'Boothamangalam',
},
{
  value: 'Sathanur',
  label: 'Sathanur',
},
{
  value: 'Others',
  label: 'Others',
}]

const LoginView = () => {
  const [selectedDate] = useState(moment().format("MM-DD-YYYY hh:mm A"));

  const [area, setArea] = React.useState('Koothanallur');

  const todayDate = moment();
  // const onDateChange = (date, value) => {
  //   setDate(value);
  // };

  const dateFormatter = str => {
    return str;
  };

  const [alertStatus, setalertStatus] = React.useState('');
  const [alertMessage, setalertMessage] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Page
      className={classes.root}
      title="Book home colections | GK Lab"
    >
      <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alertStatus}>
          {alertMessage}
        </Alert>
      </Snackbar>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="success-dialog-title"
        aria-describedby="success-dialog-description"
      >
        <DialogTitle id="success-dialog-title">Success</DialogTitle>
        <DialogContent>
          <DialogContentText id="success-dialog-description">
            {alertMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Grid
        container
        direction="column"
        justify="space-evenly"
        style={{ minHeight: '100%', paddingTop: '30px' }}
      >
        <Grid
          item
          xs={12}>
          <Container maxWidth="xs">
            <Typography variant="h4" color="primary" style={{ marginBottom: 15 }}>
              Schedule your home appointment
            </Typography>
            <Formik
              initialValues={{
                mobile: mobileNumber,
                date_time: selectedDate,
                address: '',
                area_name: area,
                remarks: '',
                firebaseToken: Cookies.get('fireBaseToken') ? Cookies.get('fireBaseToken') : ''
              }}
              validationSchema={Yup.object().shape({
                mobile: Yup.string().max(255).required('Mobile Number required'),
                address: Yup.string().max(255).required('Address required')
              })}
              onSubmit={(values, actions) => {
                actions.setSubmitting(false)

                fetch(process.env.REACT_APP_API_URL + 'booking.php', {
                  method: 'POST',
                  body: encryptString(values)
                })
                  .then(response => response.json())
                  .then(data => {
                    if (data.status === "success") {
                      setalertStatus('success')
                      setalertMessage(data.message)
                      setOpen(true)
                      setTimeout(() => {
                        navigate('/app/test-history')
                      }, 2000);
                    }
                    if (data.status === "failed") {
                      setalertStatus('error')
                      setalertMessage(data.message)
                      setOpen(true)
                    }
                  }).catch(function (error) {
                    console.log(error);
                  });
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue
              }) => (
                <form onSubmit={handleSubmit}>
                  <TextField
                    margin="normal"
                    error={Boolean(touched.mobile && errors.mobile)}
                    fullWidth
                    helperText={touched.mobile && errors.mobile}
                    label="Mobile Number"
                    name="mobile"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="mobile"
                    value={values.mobile}
                    variant="outlined"
                    autoComplete="off"
                  />
                  <TextField
                    margin="normal"
                    error={Boolean(touched.address && errors.address)}
                    fullWidth
                    helperText={touched.address && errors.address}
                    label="Address"
                    name="address"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.address}
                    variant="outlined"
                    autoComplete="off"
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    error={Boolean(touched.area_name && errors.area_name)}
                    helperText={touched.area_name && errors.area_name}
                    id="area_name"
                    name="area_name"
                    select
                    label="Area"
                    value={values.area_name}
                    onChange={handleChange}
                    variant="outlined"
                  >
                    {areaList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <KeyboardDateTimePicker
                        name="date_time"
                        id="date_time"
                        value={values.date_time}
                        margin="normal"
                        fullWidth
                        label="Choose Date & Time"
                        onChange={(value) => setFieldValue('date_time', moment(value).format("YYYY-MM-DD hh:mm A"))}
                        autoOk={true}
                        showTodayButton={true}
                        format="MM-DD-YYYY hh:mm A"
                        minDate={todayDate}
                        maxDate={moment(todayDate).add(10, 'days')}
                        rifmFormatter={dateFormatter}
                        variant="outlined"
                      />
                    </div>
                  </MuiPickersUtilsProvider>
                  <TextField
                    margin="normal"
                    error={Boolean(touched.remarks && errors.remarks)}
                    fullWidth
                    helperText={touched.remarks && errors.remarks}
                    label="Remarks"
                    name="remarks"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.remarks}
                    variant="outlined"
                    autoComplete="off"
                  />
                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Book Now
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>

            <ButtonControllers hideButton={'booking'} />
          </Container>
        </Grid>
      </Grid>
    </Page>
  );
};

export default LoginView;
