import React from 'react';
import { v4 as uuid } from 'uuid';
import moment from 'moment';

import PropTypes from 'prop-types';
import {
    Box,
    makeStyles,
    Card,
    Button, Typography, Divider
} from '@material-ui/core';

import { useNavigate } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'transparent',
        padding: "0",
        display: 'grid',
        gridTemplateColumns: "auto auto",
        alignItems: "center",
        justifyContent: 'space-between',
        border: 'none',
        boxShadow: 'none',
    },
    patientInfo: {
        fontSize: "14px",
        paddingRight: "10px",
        color: theme.palette.secondary.main,
        fontWeight: 600
    },
    regNo: { fontSize: "12px", fontWeight: '400' },
    successBtn: { backgroundColor: "#4caf50", color: "white", },
    ongoingBtn: { backgroundColor: "#ff5722", color: "white", },
    notYetBtn: { backgroundColor: "#ffdf54", },
}));

const RecentReports = ({ className, ...rest }) => {
    const { row } = rest;
    const classes = useStyles()
    const navigate = useNavigate();

    const viewStatus = (id) => {
        navigate('/app/status/' + id)
    };

    const getColorCode = (status) => {
        switch (status) {
            case "TESTING PROCESS":
                return classes.ongoingBtn
            case "REPORT READY":
                return classes.successBtn
            case "COMPLETED":
                return classes.successBtn
            default:
                return classes.notYetBtn
        }
    }
    return (
        <><Card className={classes.root} key={uuid()} onClick={() => viewStatus(row.slp_regno)}>
            <Box className={classes.patientInfo}>
                <Typography className={classes.regNo} component="p">
                    Reg No: {row.slp_regno + " | Date: " + moment(row.slp_date).format("DD-MM-YYYY")}
                </Typography>
                <Typography component="div" variant="h6" style={{
                    textTransform: 'capitalize', textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    fontSize: 16
                }}>
                    {(row.slp_pt_name).toLowerCase()}
                </Typography>
            </Box>
            <Button className={getColorCode(row.slp_status)} style={{ lineHeight: '1.1', margin: 0, boxShadow: "rgb(0 0 0 / 31%) 0px 0px 1px 0px, rgb(0 0 0 / 25%) 0px 4px 6px -2px", width: '100px', padding: '8px', textTransform: 'capitalize', fontWeight: 400 }}>
                {(row.slp_status ? row.slp_status : "Pending").toLowerCase()}
            </Button>
        </Card>
            <Divider style={{ margin: '5px auto', width: '90%' }} />
        </>
    );
};

RecentReports.propTypes = {
    className: PropTypes.string
};

export default RecentReports;