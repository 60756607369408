import {
  TableRow,
  withStyles
} from '@material-ui/core';

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '& th': {
      backgroundColor: theme.palette.action.hover,
    }
  },
}))(TableRow);

export default StyledTableRow;
