import { createMuiTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    body: '#363537',
    background: {
      dark: '#000',
      default: colors.blue[50],
      paper: colors.blue[50]
    },
    primary: {
      main: colors.blueGrey[900]
    },
    secondary: {
      main: colors.blueGrey[900]
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    }
  },
  shadows,
  typography
});

export default darkTheme;
