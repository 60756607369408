import React from 'react';
import { v4 as uuid } from 'uuid';

import {
  Step,
  StepLabel,
  Stepper,
  Button,
  Container,
  Grid,
  makeStyles,
  withStyles,
  Typography,
  Table, TableBody, TableContainer, TableHead, TableRow
} from '@material-ui/core';

import StyledTableCell from 'src/components/StyledTableCell';
import StyledTableRow from 'src/components/StyledTableRow';
import { useNavigate } from "react-router-dom";

import clsx from 'clsx';
import Page from 'src/components/Page';
import decryptString from 'src/utils/decryptString';
import Loader from 'src/services/Loader';
import { useParams } from 'react-router-dom';
import PropTypes from "prop-types";

import AddIcon from '@material-ui/icons/Add';
import LoopIcon from '@material-ui/icons/Loop';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import StepConnector from '@material-ui/core/StepConnector';
import moment from 'moment/moment';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  buttonStyle: {
    width: 200
  },


}));


const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 17,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 2,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 35,
    height: 35,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <AddIcon />,
    2: <LoopIcon />,
    3: <DoneIcon />,
    4: <DoneAllIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

function getSteps() {
  return ['Sample Collected', 'Test Proccessing', 'Report Ready', 'Report Delivered'];
}


const TestInfo = () => {
  const navigate = useNavigate();

  const param = useParams();
  const classes = useStyles();
  const steps = getSteps();

  const [alertMessage, setalertMessage] = React.useState('');

  const [testStatus, setTestStatus] = React.useState('');

  React.useEffect(() => {
    let mounted = true;

    fetch(process.env.REACT_APP_API_URL + 'get_report_status.php?reg_id=' + param.id, {
      method: 'POST',
      body: localStorage.getItem('eToken')
    })
      .then(response => response.json())
      .then(data => {

        if (data.status === "success" && mounted) {
          setTestStatus(JSON.parse(decryptString(JSON.stringify(data?.report_data))))
        }

        if (data.status === "failed" && mounted) {
          setalertMessage(data.message)
        }
      }).catch(function (error) {
        console.log(error);
      });
    return () => mounted = false;

  }, [param.id]);

  function setViewedStatus(rID) {
    fetch(process.env.REACT_APP_API_URL + 'post_reports_view.php?report_id=' + rID, {
      method: 'POST',
      body: localStorage.getItem('eToken')
    })
      .then(response => response.json())
      .then(data => {

      }).catch(function (error) {
        console.log(error);
      });
  }

  const getActiveStep = (key) => {
    switch (key) {
      case "TESTING PROCESS":
        return 1
      case "REPORT READY":
        return 2
      case "COMPLETED":
        return 3
      default:
        return 0
    }
  }

  return (
    !(testStatus) ? <Loader datafromparent={{ errorMSG: alertMessage }} /> : <Page
      className={classes.root}
      title="Settings"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            lg={12}
            md={12}
            xl={9}
            xs={12}
          >
            <TableContainer>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Reg No</StyledTableCell>
                    <StyledTableCell>{testStatus.slp_regno}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row">
                      Date
                    </StyledTableCell>
                    <StyledTableCell>{moment(testStatus.slp_date).format("DD-MM-YYYY hh:mm A")}</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row">
                      Name
                    </StyledTableCell>
                    <StyledTableCell>{testStatus.slp_pt_name}</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row">
                      Age
                    </StyledTableCell>
                    <StyledTableCell>{testStatus.slp_pt_age}</StyledTableCell>
                  </StyledTableRow>
                  {(testStatus.slp_pt_address && testStatus.slp_pt_address !== "") ?
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Address
                      </StyledTableCell>
                      <StyledTableCell>{testStatus.slp_pt_address}</StyledTableCell>
                    </StyledTableRow> : null}
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row">
                      Mobile No
                    </StyledTableCell>
                    <StyledTableCell>{testStatus.slp_pt_mobile}</StyledTableCell>
                  </StyledTableRow>
                  {testStatus.slp_investigation ? <StyledTableRow>
                    <StyledTableCell component="th" scope="row">
                      Investigations
                    </StyledTableCell>
                    <StyledTableCell>{(testStatus.slp_investigation).split(',').map((item) =>
                      <Typography style={{ fontSize: 14 }} key={uuid()}>{item}</Typography>
                    )}</StyledTableCell>
                  </StyledTableRow> : null}
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row">
                      Ref By.
                    </StyledTableCell>
                    <StyledTableCell>{testStatus.slp_doctor}</StyledTableCell>
                  </StyledTableRow>
                  {(testStatus.slp_visit_type && testStatus.slp_visit_type !== "") ?
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Visit Type
                      </StyledTableCell>
                      <StyledTableCell>{testStatus.slp_visit_type}</StyledTableCell>
                    </StyledTableRow> : null}
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row">
                      Status
                    </StyledTableCell>
                    <StyledTableCell>{testStatus.slp_status ? testStatus.slp_status : "Pending"}</StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Stepper alternativeLabel activeStep={getActiveStep(testStatus.slp_status)} connector={<ColorlibConnector />}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>

          {
            testStatus.report_file ?
              <>
                <Grid item
                  lg={4}
                  xs={12}
                  align="center"
                >
                  <Button size="large" variant="contained" color='primary' onClick={() => { navigate(`/app/report/view?url=` + testStatus.report_file + `&rid=` + testStatus.slp_regno) }} className={classes.buttonStyle} style={{ margin: 0 }}>View Report</Button></Grid>
                <Grid item
                  lg={4}
                  xs={12}
                >
                  <a href={testStatus.report_file} target="_blank" rel="noopener noreferrer" download onClick={() => { setViewedStatus(testStatus.report_file) }} style={{ display: 'block', textAlign: 'center', margiTop: '10px' }}>
                    <Button size="large" variant="contained" color='primary' className={classes.buttonStyle}>Download Report</Button>
                  </a>
                </Grid></>
              : null
          }

        </Grid>
      </Container>
    </Page >
  );
};

export default TestInfo;
