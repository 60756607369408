import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import decryptString from 'src/utils/decryptString';

import PropTypes from 'prop-types';

import {
  Box,
  Drawer,
  List,
  makeStyles,
} from '@material-ui/core';

import {
  Loader as LoaderIcon,
  Save as SaveIcon,
  Calendar as CalendarIcon,
  Package as PackageIcon,
  Compass as CompassIcon
} from 'react-feather';

const items = [
  {
    href: '/app/report',
    icon: SaveIcon,
    title: 'View Reports',
    user: 'all'
  },
  {
    href: '/app/status',
    icon: CompassIcon,
    title: 'Sample History',
    user: 'all'
  },
  {
    href: '/app/test-history',
    icon: LoaderIcon,
    title: 'Booking History',
    user: 'all'
  },
  {
    href: '/book-test',
    icon: CalendarIcon,
    title: 'Book Appointment',
    user: 'all'
  },
  {
    href: '/packages',
    icon: PackageIcon,
    title: 'Health Packages',
    user: 'all'
  },
];


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  },
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  tyobeLogo: {
    width: '100px',
    margin: '10px auto 0px',
    display: 'block'
  }
}));

const NavBar = ({ onNotificationClose, openNotification }) => {
  const classes = useStyles();

  // const [notifications, setnotifications] = React.useState('');
  // const authToken = localStorage.getItem('eToken');
  const location = useLocation();

  useEffect(() => {
    if (openNotification && onNotificationClose) {
      onNotificationClose();
    }
  }, [location.pathname, onNotificationClose]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <List>


      </List>

    </Box>
  );

  return (
    <>
      <Drawer
        anchor="right"
        classes={{ paper: classes.mobileDrawer }}
        onClose={onNotificationClose}
        open={openNotification}
        variant="temporary"
      >
        {content}
      </Drawer>
    </>
  );
};

NavBar.propTypes = {
  onNotificationClose: PropTypes.func,
  openNotification: PropTypes.bool
};

NavBar.defaultProps = {
  onNotificationClose: () => { },
  openNotification: false
};

export default NavBar;
