import React from 'react';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import {
  TableCell,
  makeStyles,
  Button
} from '@material-ui/core';
import StyledTableRow from 'src/components/StyledTableRow';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    }
  }, tableCell: {
    padding: '8px 3px',
  },
  tableArrow: {
    padding: '0 0 0 5px'
  }
}));
function setViewedStatus(rID) {
  fetch(process.env.REACT_APP_API_URL + 'post_reports_view.php?report_id=' + rID, {
    method: 'POST',
    body: localStorage.getItem('eToken')
  })
    .then(response => response.json())
    .then(data => {

    }).catch(function (error) {
      console.log(error);
    });
}
const Row = ({ className, ...rest }) => {
  const { row } = rest;
  const classes = useStyles()
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <StyledTableRow className={classes.root} key={uuid()}>
        <TableCell align="left" style={{ width: '110px' }}>{moment(row.rr_date).format("DD-MM-YYYY")}</TableCell>
        <TableCell align="left">{row.rr_regno}</TableCell>
        <TableCell align="center">
          <Button variant='text' onClick={() => { navigate(`/app/report/view?url=` + row.rr_report_url + `&rid=` + row.rr_regno) }} style={{ margin: 0, padding: 0, fontSize: 14, color: '#2f2eef' }}>View</Button>
          <a href={row.rr_report_url} target="_blank" rel="noopener noreferrer" download onClick={() => { setViewedStatus(row.rr_regno) }} style={{ margin: 0, padding: 0, fontSize: 14, color: '#2f2eef' }}>Download</a>
        </TableCell>
      </StyledTableRow>
    </React.Fragment>
  );
};

Row.propTypes = {
  className: PropTypes.string
};

export default Row;
