import React from "react";

import {
    makeStyles,
    Typography,
    Grid,
    IconButton,
} from '@material-ui/core';

import DescriptionIcon from '@material-ui/icons/Description';
import TodayIcon from '@material-ui/icons/Today';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import HistoryIcon from '@material-ui/icons/History';




const useStyles = makeStyles((theme) => ({
    styledIconBox: {
        color: theme.palette.grey[800],
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        borderRadius: '10px',
        padding: '10px 3px',
        width: '100%',
        '& svg': { fontSize: '45px', color: theme.palette.secondary.main },
        '& span': {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            overflow: 'hidden',
            width: '100%'
        }
    }
}));

function PackagesSection({ ...props }) {
    const classes = useStyles();
    // React.useEffect(() => { console.log(props.hideButton) }, [])
    return (
        <>
            <Grid container item style={{ padding: '30px  10px' }} lg={12} spacing={1}>
                {props.hideButton != "report-history" ? <Grid item xs={4}>
                    <IconButton className={classes.styledIconBox} href="/app/report">
                        <DescriptionIcon />
                        <Typography variant="button">
                            Report History
                        </Typography>
                    </IconButton>
                </Grid> : null}
                {props.hideButton != "booking" ? <Grid item xs={4}>
                    <IconButton className={classes.styledIconBox} href="/book-test">
                        <TodayIcon />
                        <Typography variant="button">
                            Book Appointment
                        </Typography>
                    </IconButton>
                </Grid> : null}
                {props.hideButton != "appointment-history" ? <Grid item xs={4}>
                    <IconButton className={classes.styledIconBox} href="/app/test-history">
                        <HistoryIcon />
                        <Typography variant="button">
                            Booking History
                        </Typography>
                    </IconButton>
                </Grid> : null}
                {props.hideButton != "" ? <Grid item xs={4}>
                    <IconButton className={classes.styledIconBox} href="/packages"  >
                        <LoyaltyIcon />
                        <Typography variant="button">
                            Health Packages
                        </Typography>
                    </IconButton>
                </Grid> : null}
            </Grid>
        </>
    );
}

export default PackagesSection;
