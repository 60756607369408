export default {
  body: {
    fontWeight: 400,
    fontSize: 14,
  },
  h1: {
    fontWeight: 500,
    fontSize: 35,
    letterSpacing: '-0.24px'
  },
  h2: {
    fontWeight: 500,
    fontSize: 29,
    letterSpacing: '-0.24px'
  },
  h3: {
    fontWeight: 500,
    fontSize: 24,
    letterSpacing: '-0.06px'
  },
  h4: {
    fontWeight: 600,
    fontSize: 18,
    textTransform: 'capitalize'
  },
  h5: {
    fontWeight: 500,
    fontSize: 16,
    letterSpacing: '-0.05px'
  },
  h6: {
    fontWeight: 600,
    fontSize: 14,
    letterSpacing: '-0.05px'
  },
  overline: {
    fontWeight: 500
  },
  button: {
    fontSize: 12,
    letterSpacing: '-0.05px',
    textTransform: 'capitalize',
    lineHeight: 1.2,
    marginTop: '5px',

  },
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    'Source Sans Pro',
    'Roboto',
    'sans-serif'
  ].join(','),
};
