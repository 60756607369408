import React from 'react';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';

import {
  Container,
  makeStyles,
  TablePagination,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';

import decryptString from 'src/utils/decryptString';
import Loader from 'src/services/Loader';
import RecentReports from 'src/views/sections/RecentReports';
import ButtonControllers from "src/views/sections/ButtonControllers.js";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '90%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));



const StatusView = ({ className, ...rest }) => {
  const [pageData, setPageData] = React.useState('');

  const [syncStatus, setsyncStatus] = React.useState('');
  const [alertMessage, setalertMessage] = React.useState('');


  const [dataCount, setdataCount] = React.useState(20);
  const [dataCountSetted, setdataCountStatus] = React.useState(false);

  if (!dataCountSetted && pageData.length) {
    setdataCount(pageData.length)
    setdataCountStatus(true)
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  React.useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + 'get_report_status.php', {
      method: 'POST',
      body: localStorage.getItem('eToken')
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === "success") {
          setPageData(JSON.parse(decryptString(JSON.stringify(data?.report_data))))
        } else {
          setsyncStatus(data.status)
        }

        if (data.status === "failed") {
          setalertMessage(data.message)
        }
      }).catch(function (error) {
        console.log(error);
      });
  }, []);

  const classes = useStyles();


  return (
    (!pageData && !syncStatus) ? <Loader datafromparent={{ errorMSG: alertMessage }} /> :
      <Page
        className={classes.root}
        title="Sample History | GK Lab"
      >
        {pageData.length > 0 ?
          <Container maxWidth="md" className={clsx(classes.root, className)}
            {...rest}>
            <Typography variant="h4" color="primary" style={{ marginBottom: 15 }}>
              Sample History
            </Typography>

            {pageData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <RecentReports row={row} key={uuid()} />
            ))}
            {pageData.length > 9 ?
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={dataCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              /> : null}
          </Container >
          : <Container maxWidth="md" className={clsx(classes.root, className)} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <img
              alt="Logo"
              src="/static/no-data.svg"
              width="260"
            />
            <Typography>
              No Sample History Found
            </Typography>
          </Container>
        }
        <Container maxWidth="md">
          <ButtonControllers hideButton={'appointment-history'} />
        </Container>
      </Page >
  );
};

StatusView.propTypes = {
  className: PropTypes.string
};

export default StatusView;
