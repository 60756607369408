import React, { useState } from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import { ToastProvider } from 'react-toast-notifications'
import GlobalStyles from 'src/components/GlobalStyles';
import redTheme from 'src/theme';
import darkTheme from 'src/theme/dark';
import routes from 'src/routes';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-W8ZHVXZ'
}

TagManager.initialize(tagManagerArgs)

const App = () => {
  const [themeColor, setTheme] = useState('light');
  const [themeSetted, setThemeStatus] = useState(false);

  if (!themeSetted) {
    setTheme(localStorage.getItem('appTheme'))
    setThemeStatus(true)
  }


  const routing = useRoutes(routes);
  return (
    <ThemeProvider theme={themeColor === 'dark' ? darkTheme : redTheme}>
      <GlobalStyles />
      <ToastProvider>
        {routing}
      </ToastProvider>
    </ThemeProvider>
  );
};

export default App;
