import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { useNavigate, Navigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  makeStyles,
  withStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import encryptString from 'src/utils/encryptString';
import LogoVertical from 'src/components/LogoVertical';
import Footer from 'src/layouts/Footer';
import Cookies from 'js-cookie';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    backgroundImage: "url('/static/login-bg.png')",
    backgroundSize: 'cover',
    "@media (min-width: 992px)": {
      backgroundImage: "url('/static/login-bg-desktop.png')",
    }
  }
}));

const LoginView = (props) => {
  const [showPasswordField, setshowPasswordField] = React.useState(false)

  const [alertStatus, setalertStatus] = React.useState('');
  const [alertMessage, setalertMessage] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };


  const [showConfirmation, setConfirmation] = React.useState(false);
  const [submitAPI, setSubmitAPI] = React.useState(false);
  const [mobNumber, setMobNum] = React.useState('');
  const formREF = React.useRef();


  const authToken = localStorage.getItem('eToken');


  const classes = useStyles();
  const navigate = useNavigate();
  const phoneRegExp = /^[6-9]\d{9}$/

  const ValidationTextField = withStyles((theme) => ({
    root: {
      borderColor: theme.palette.common.white,
      borderRadius: 10,
      overflow: 'hidden',
      borderWidth: 2,
      width: 280,
      backgroundColor: theme.palette.common.white,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '& input': {
        borderColor: theme.palette.common.white,
        borderRadius: 10,
        backgroundColor: theme.palette.common.white,
      },
      '&:hover': {
        backgroundColor: theme.palette.common.white,
      }
    }

    // root: {
    //   '& input:valid + fieldset': {
    //     borderColor: theme.palette.common.white,
    //     borderRadius: 10,
    //     backgroundColor: theme.palette.common.white,
    //     borderWidth: 2,
    //   },
    //   '& input:invalid + fieldset': {
    //     borderColor: theme.palette.secondary.main,
    //     borderWidth: 2,
    //   },
    //   '& input:valid:focus + fieldset': {
    //     borderLeftWidth: 6,
    //     borderColor: theme.palette.secondary.main,
    //     padding: '4px !important', // override inline-style
    //   },
    // },
  }))(TextField);

  const StyledButton = withStyles((theme) => ({
    root: {
      borderRadius: 10,
      width: 200,
      padding: '13px',
      background: '#1d1d1d',
      boxShadow: '1px 3px 7px 1px rgb(0 0 0 / 32%)',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  }))(Button);

  return (
    authToken ? <Navigate to="/" /> : <Page
      className={classes.root}
      title="Login | GK Lab"
    >
      <Dialog
        open={showConfirmation}
        onClose={() => setConfirmation(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm your mobile number"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Want to proceed with {mobNumber}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmation(false)} color="primary" autoFocus>
            Change
          </Button>
          <Button type="submit" onClick={() => (setConfirmation(false), setSubmitAPI(true), formREF.current.handleSubmit())} color="primary">
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alertStatus}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <Grid
        container
        display="flex"
        direction="column"
        justify="space-evenly"
        style={{ minHeight: '100%' }}
      >
        <Grid
          item
          xs={12} align="center">
          <LogoVertical style={{ margin: "auto", width: "95%", maxWidth: '380px' }} />
        </Grid>
        <Grid
          item
          xs={12}>
          <Container maxWidth={false}>
            <Formik
              innerRef={formREF}
              initialValues={{
                mobile: '',
                password: '',
                firebaseToken: Cookies.get('fireBaseToken') ? Cookies.get('fireBaseToken') : ''
              }}
              validationSchema={Yup.object().shape({
                mobile: Yup.string().max(10).required('Mobile Number required').matches(phoneRegExp, 'Phone number is not valid'),
                password: showPasswordField ? Yup.string().max(255).required('Password required') : null
              })}
              onSubmit={(values, actions) => {
                actions.setSubmitting(false)
                if (submitAPI) {
                  fetch(process.env.REACT_APP_API_URL + 'auth_check.php', {
                    method: 'POST',
                    body: encryptString(values)
                  })
                    .then(response => response.json())
                    .then(data => {
                      if (data.status_code === "SS01" || data.status_code === "SS02") {
                        setalertStatus('success')
                        setalertMessage(data.message)
                        setOpen(true)
                        setshowPasswordField(true)
                      }
                      if (data.token) {
                        setalertStatus('success')
                        setalertMessage(data.message)
                        setOpen(true)
                        localStorage.setItem('eToken', JSON.stringify(data.token))

                        localStorage.getItem("prevPath") ? navigate(localStorage.getItem("prevPath"), { replace: true }) :
                          navigate('/', { replace: true })

                      }
                      if (data.status === "failed") {
                        setalertStatus('error')
                        setalertMessage(data.message)
                        setOpen(true)
                      }
                    }).catch(function (error) {
                      console.log(error);
                    });
                } else {
                  setMobNum(formREF.current.values.mobile)
                  setConfirmation(true)
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box my={1} display="flex"
                    justifyContent="center"
                    alignItems="flex-end">
                    <ValidationTextField
                      error={Boolean(touched.mobile && errors.mobile)}
                      fullWidth
                      helperText={touched.mobile && errors.mobile}
                      label="Mobile Number"
                      margin="normal"
                      name="mobile"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="mobile"
                      value={values.mobile}
                      variant="filled"
                      autoComplete="off"
                    />
                  </Box>
                  {showPasswordField ?
                    <Box my={1} display="flex"
                      flexWrap="wrap"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center">
                      <ValidationTextField
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        label="Password"
                        margin="normal"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        value={values.password}
                        variant="filled"
                      />
                      <a style={{ color: "white", fontSize: 12, margin: '10px 0' }} align="right" href='tel:+919842478649'>
                        Forgot Password
                      </a>
                    </Box> : null}
                  <Box my={1} display="flex"
                    justifyContent="center"
                    alignItems="flex-end">
                    <StyledButton
                      color="secondary"
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                      align="center"
                    >
                      Login
                    </StyledButton>
                  </Box>
                </form>
              )}
            </Formik>
            <Box my={1} display="flex"
              justifyContent="center"
              alignItems="flex-end"><StyledButton
                color="secondary"
                size="large"
                href="/"
                variant="contained"
              >
                Back to home
              </StyledButton></Box>
          </Container>
        </Grid>
      </Grid>
      <Footer />
    </Page>
  );
};

export default LoginView;
