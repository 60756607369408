import React from "react";
import { v4 as uuid } from 'uuid';

import {
  Grid,
  Typography,
  Container,
  Button,
  TextField,
  makeStyles,
  Box
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

import decryptString from 'src/utils/decryptString';

import BannerCarousel from "src/views/sections/BannerCarousel.js";
import PackagesSection from "src/views/sections/PackagesSection.js"
import RecentReports from "src/views/sections/RecentReports.js"
import ButtonControllers from "src/views/sections/ButtonControllers.js";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0
  },
  margin: {
    margin: theme.spacing(1),
  },
  headerBox: {
    justifyContent: 'center',
    padding: '30px 15px 80px',
    backgroundImage: "url('/static/header-bg.png')"
  },
  recentBox: {
    backgroundColor: theme.palette.common.white,
    padding: 10,
    borderRadius: 5,
    marginTop: '-50px'
  },
  bannerCarousel: {
    marginTop: '30px',
    [theme.breakpoints.up('lg')]: {
      marginTop: '-40px'
    }
  },

  titleText: {
    color: theme.palette.common.white,
    fontWeight: "400",
    fontSize: 18,
  },
  nameText: {
    color: theme.palette.common.white,
    fontWeight: "600",
    fontSize: 22,
  },
  buttonStyle: {
    backgroundColor: '#ffffff66', color: '#fff', border: 'none', boxShadow: 'rgb(0 0 0 / 22%) 2px 3px 6px', textTransform: 'capitalize', fontWeight: 'normal'
  },
  styledImageText: {
    textAlign: 'center',
    marginTop: '30px',
    '& img': { width: 120 },
    '& h5': { marginTop: 10, color: theme.palette.secondary.main, fontWeight: 800, fontSize: 22 },
    '& h6': { color: theme.palette.secondary.main, fontWeight: 600, lineHeight: 1 },
  },
}));

function Index() {
  const authToken = localStorage.getItem('eToken');

  const [logStatus, setlogOutStatus] = React.useState(false);
  const [customerData] = React.useState(authToken ? JSON.parse(decryptString(authToken)) : '');

  const [pageData, setPageData] = React.useState('');
  const [isNameEmpty, setNameEmpty] = React.useState(false);
  const [userName, setUserName] = React.useState('');

  const logOutClick = () => {
    setlogOutStatus(true)
  }

  const handleClose = () => {
    setlogOutStatus(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('eToken')
    navigate('/login', { replace: true })
  };


  const nameInput = () => {
    if (userName.trim() !== "") {
      fetch(process.env.REACT_APP_API_URL + 'update_name.php?name=' + userName.trim(), {
        method: 'POST',
        body: authToken
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === "success") {
            setNameEmpty(false)
            localStorage.setItem('eToken', JSON.stringify(data.token))
            window.location.reload()
          }
        }).catch(function (error) {
          console.log(error);
        });
    } 
  };

  const handleTextInputChange = event => {
    setUserName(event.target.value);
  };

  const classes = useStyles();
  const navigate = useNavigate();



  React.useEffect(() => {
    if (authToken) {
      fetch(process.env.REACT_APP_API_URL + 'get_report_status.php?limit=3', {
        method: 'POST',
        body: authToken
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === "success") {
            setPageData(JSON.parse(decryptString(JSON.stringify(data?.report_data))))
          }

          if (data.is_name_empty == true) {
            setNameEmpty(true)
          }
        }).catch(function (error) {
          console.log(error);
        });
    }
  }, [authToken]);

  return (
    <>
      <Container maxWidth={false} className={classes.root}>
        {logStatus ? <Dialog
          open={true}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Logout"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to logout now?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              Cancel
            </Button>
            <Button onClick={handleLogout} color="primary">
              Logout
            </Button>
          </DialogActions>
        </Dialog> : null}
        {
          isNameEmpty ? <Dialog
            open={true}
          >
            <DialogTitle id="alert-dialog-title">Kindly enter your name</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="normal"
                fullWidth
                label="Name"
                name="name"
                type="text"
                autoComplete="off"
                onChange={handleTextInputChange}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={nameInput} color="primary">
                Proceed
              </Button>
            </DialogActions>
          </Dialog> : null
        }
        <Grid container style={{ justifyContent: "center", alignItems: 'flex-start', padding: 0 }}>
          {/* Header Content */}
          <Grid item container className={classes.headerBox}>
            <Grid item xs={8} lg={6}>
              <Typography variant="h2" className={classes.titleText}>
                Hello,
              </Typography>
              <Typography variant="h1" className={classes.nameText}>
                {customerData.name ? customerData.name : customerData.mobile ? customerData.mobile : "Guest"}
              </Typography>
            </Grid>
            <Grid item xs={4} align='right'>
              {authToken ? <Button
                size="small"
                variant="outlined"
                onClick={logOutClick}
                className={classes.buttonStyle}
              > Logout
              </Button> : <Button
                size="small"
                className={classes.buttonStyle}
                variant="outlined"
                onClick={() => navigate('/login', { replace: true })} > Login
              </Button>}
            </Grid>
          </Grid>
          <Grid container item xs={12} lg={5} className={classes.margin}>
            {/* Recent Reports */}
            <Grid item xs={12} lg={12}>
              <Box className={classes.recentBox} boxShadow={5}>
                <Typography gutterBottom variant="h4" color="primary">
                  Your recent reports
                </Typography>

                {pageData ? pageData.length > 0 ?
                  pageData?.map((row) => (
                    <RecentReports row={row} key={uuid()} />
                  )) : <img src="/static/blur-nodata.jpg" alt='' />
                  : <Button color="primary" href="/login" style={{ width: "100%", textTransform: 'capitalize', padding: 0, fontWeight: 600 }} variant="text">
                    <img src="/static/blur.jpg" alt='' />
                  </Button>
                }
                {pageData.length > 2 ?
                  <Button color="primary" href="/app/status" style={{ width: "100%", textTransform: 'capitalize', padding: 0, fontWeight: 600 }} variant="text">
                    View All
                  </Button> : null}
              </Box>
            </Grid>


            <ButtonControllers hideButton={'appointment-history'} />
          </Grid>
          <Grid item lg={6} className={classes.bannerCarousel}>
            <BannerCarousel />
          </Grid>
          <Grid item lg={4} xs={12} style={{ padding: '0  15px' }}>
            <Typography variant="h4" color="primary" style={{ marginTop: 30 }}>
              Popular Health Packages
            </Typography>
            <PackagesSection category={1} />
          </Grid>
          <Grid container item lg={3} xs={12} style={{ padding: '0  15px', alignItems: 'flex-start' }}>
            <Grid item xs={4} md={3} lg={6} className={classes.styledImageText}>
              <img src="/static/sample.png" alt="" />
              <Typography variant="h5">
                5 Lakh +
              </Typography>
              <Typography variant="h6">
                Samples Tested
              </Typography>
            </Grid>
            <Grid item xs={4} md={3} lg={6} className={classes.styledImageText}>
              <img src="/static/home-visit.png" alt="" />
              <Typography variant="h5">
                60000  +
              </Typography>
              <Typography variant="h6">
                Home Services
              </Typography>
            </Grid>
            <Grid item xs={4} md={3} lg={6} className={classes.styledImageText}>
              <img src="/static/families.png" alt="" />
              <Typography variant="h5">
                3000 +
              </Typography>
              <Typography variant="h6">
                Families Served
              </Typography>
            </Grid>
            <Grid item xs={4} md={3} lg={6} className={classes.styledImageText}>
              <img src="/static/exp.png" alt="" />
              <Typography variant="h5">
                23 +
              </Typography>
              <Typography variant="h6">
                Years of Experience
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={4} xs={12} style={{ padding: '0  15px' }}>
            <Typography variant="h4" color="primary" style={{ marginTop: 50 }}>
              Our Certifications
            </Typography>
            <img src="/static/certification.png" style={{ width: 200, marginTop: 20, marginBottom: 50 }} alt="" />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Index;
