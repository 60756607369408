import React from 'react';

import {
  Container,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';

import PackagesSection from "src/views/sections/PackagesSection.js"
import Page from 'src/components/Page';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
}));



const TestInfo = () => {
  const classes = useStyles();


  return (
    <Page
      className={classes.root}
      title="Health Packages"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography variant="h4" color="primary" >
              Health Packages
            </Typography>
            <PackagesSection/>
          </Grid>
        </Grid>
      </Container>
    </Page >
  );
};

export default TestInfo;
