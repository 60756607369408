import React from "react";

import "src/assets/css/carousel.css";
import "src/assets/css/custom.css";

import {
  Carousel,
  CarouselItem,
  CarouselIndicators
} from "reactstrap";

import decryptString from 'src/utils/decryptString';

function BannerCarousel() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const [pageData, setPageData] = React.useState([]);

  React.useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + 'get_promo.php', {
      method: 'POST',
      body: localStorage.getItem('eToken')
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === "success") {
          setPageData(JSON.parse(decryptString(JSON.stringify(data?.promo_data))))
          setTimeout(() => {
            goToIndex(1)
          }, 5000);
        }

      }).catch(function (error) {
        console.log(error);
      });
  }, []);


  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === pageData.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? pageData.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
      >
        <CarouselIndicators
          items={pageData}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {pageData.map((item, i) =>
        (
          <CarouselItem
            onExiting={onExiting}
            onExited={onExited}
            key={i}
          >
            <img
              alt="..."
              className="img-responsive"
              src={item.rp_url}
              style={{ width: "100%" }}
            />
          </CarouselItem>
        )
        )}
      </Carousel>
      {" "}
    </>
  );
}

export default BannerCarousel;
