import React from "react";
import {
  Typography,
  Container
} from '@material-ui/core';

function Footer() {
  return (
    <footer className="footer">
      <Container>
        <Typography align="center" style={{ fontSize: 12 }}>
          © {new Date().getFullYear()} | <a href="https://www.tyobe.com/" target="_blank" rel="noopener noreferrer">Tyobe Technologies</a>
        </Typography>
      </Container>
    </footer>
  );
}

export default Footer;
