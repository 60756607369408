import React from 'react';
import { useNavigate, useHistory } from 'react-router-dom';
import {
  NavbarBrand
} from "reactstrap";

import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  Badge,
  IconButton,
  Toolbar,
  makeStyles,
  withStyles

} from '@material-ui/core';

import NotificationsIcon from '@material-ui/icons/Notifications';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Logo from 'src/components/Logo';

const useStyles = makeStyles(() => ({
  root: {},
  toolbar: {
    height: 60
  }
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  onNotiPanelOpen,
  ...rest
}) => {
  const classes = useStyles();
  const authToken = localStorage.getItem('eToken');
  const navigate = useNavigate();
  function goBack() {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate('/', { replace: true }); // the current entry in the history stack will be replaced with the new one with { replace: true }
    }
  }

  const StyledBadge = withStyles((theme) => ({
    badge: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: '-1px',
        left: '-1px',
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }))(Badge);

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
      style={{ background: "#fff" }}
    >
      <Toolbar className={classes.toolbar}>
        {window.location.pathname !== '/' ? <>
          <IconButton color="inherit" size="small" onClick={goBack}>
            <ArrowBackIcon color="primary" />
          </IconButton> <NavbarBrand
            data-placement="bottom"
            href="/"
            title="GK Lab"
          >
            <Logo />
          </NavbarBrand> </> : <NavbarBrand
            data-placement="bottom"
            title="GK Lab"
          >
          <Logo />
        </NavbarBrand>}

        <Box flexGrow={1} />
        {authToken ? <>
          {/* <IconButton
            color="primary"
            onClick={onNotiPanelOpen}
          >
            <StyledBadge
              overlap="circle"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              badgeContent={0}
            >
              <NotificationsIcon />
            </StyledBadge>
          </IconButton> */}
          <Hidden mdUp>
            <IconButton
              color="primary"
              onClick={onMobileNavOpen}
            >
              <MenuIcon />
            </IconButton> </Hidden></> : null}
      </Toolbar>
    </AppBar >
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
  onNotiPanelOpen: PropTypes.func,
};

export default TopBar;